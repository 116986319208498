<template>
<div class="rows">
  <div class="column">
    <div class="card">
      <div class="container">
          <p>
            Bref, la Société Coopérative Umuryango Wacu  à travers ces objectifs généraux cités ci haut va contribuer à la croissance économique du pays dans différents domaine de la vie quotidienne au Burundi. Nous nous sommes alors fixés ces objectifs car ils sont très bénéfique ; une solution étroite et durable qui permet  l’aboutissement de création d’emplois et à la lutte contre la pauvrette, le chômage et la  faim au Burundi. Vu que cette lutte qui englobe la pauvreté, le chômage, la délinquance, absence d’innovation et de créativité, le faible niveau de vie de la population urbaine et rural, la faible croissance économique dans tous les secteurs ainsi que le sous-développement nous concerne tous sans exception aucune, la Société Coopérative Umuryango Wacu espère que d’ici 5 ans, face à cette lutte, elle produira de bons résultats du point de vue économique et socioculturel du pays. 
            
            <br><br>

            Comme c’est précisé dans le Plan National du Développement du Burundi PND en sigle (2018-2027), l'état planifie les activités et les projets qu'il prévoit mettre en place d'ici dix ans. Ces projets touchent différents secteurs notamment l'agriculture, l’élevage, le commerce et l’industrie. 
          </p>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped>


.about-section {
  padding: 50px;
  text-align: center;
  background-color: #474e5d;
  color: white;
}

.card {
  padding: 0 16px;
}

.card::after, .rows::after {
  content: "";
  clear: both;
  display: table;
}

.title {
  color: grey;
}

.button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.button:hover {
  background-color: #555;
}

@media screen and (max-width: 650px) {
  .column {
    width: 100%;
    display: block;
  }
}
@media (max-width: 540px)
.card {
    text-align: center;
    padding: 56px 15px;
}
@media (max-width: 767px)
.card {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.card{
    border-top: 3px solid #fff;
    display: -ms-flexbox;
    display: flex;
    padding: 20px 20px;
    -ms-flex-align: flex-start;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 4.75px 0.25px rgba(0, 0, 0, 0.15);
    position: relative;

}
</style>