<template>
<body style="background-color: #f9fcff;">
<div class="container" style="margin-top:30px">
  <div class="row">
    <div class="col-12">
      <div class="title-heading">
        How to UpSkill
      </div>
    </div>
  </div>
  <div class="row">
    <div class = "col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">STEP 1</h4>
          <div class="card-image" style="color: #213661;">
            <i class="fas fa-laptop-code fa-5x"></i>
          </div>
          <p class="card-text ">Go through the course content thoroughly and enroll before the batch begins.</p>
        </div>
      </div>
    </div>
     <div class = "col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">STEP 2</h4>
          <div class="card-image" style="color: #5e371b;">
            <i class="fas fa-chalkboard-teacher fa-5x"></i>
          </div>
          <p class="card-text">Attend all the weekly interactive sessions from 9pm - 10pm, 4 times a week and do all your projects and assignments.</p>
        </div>
      </div>
    </div>
     <div class = "col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">STEP 3</h4>
          <div class="card-image" style="color:#bc3330;">
            <i class="fas fa-bullseye fa-5x"></i>
          </div>
          <p class="card-text">Eureka! Get certified and Get yourself placed as an intern in one of our partner startups now that you have completed the course.</p>
        </div>
      </div>
    </div>


  </div>
</div>
</body>
</template>

<style scoped>
	  .card{
      margin: 10px auto;
      box-shadow: 0px 2px 4px 0 rgba(0,0,0,0.2);
      border-radius: 6px;
      height: 100%;
      transition: 0.3s;
    }
.card:hover {
    box-shadow: 1px 10px 16px 0 rgba(0,0,0,0.3);
}
    .card-title{
      text-align: center;
      color: #666666;
      font-weight: bold;
    }
    .card-text{
          font-weight: 500;
    color: #66686b;
    }
    .card-image{
      text-align: center;
      margin: 5vh 0;
    }
    .card-text{
      text-align: center;
    }

    .title-heading{
      font-size: 3em;
      text-align: center;
      margin: 4%;
      font-weight: bold;

    }
</style>