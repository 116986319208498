<template>
	<div class="content">
		<center>
			<hr>
			<h1 style="color:red; padding-right: 10px">&mdash;</h1><h1 style="background-color:red"> Muryango Wacu-MSG</h1>
		</center>
		 <p>
			Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
			tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
			quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
			consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
			cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
			proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    	</p>
    	<p>
    		Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
    	     tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
    	     quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
    	     consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
    	     cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
    	     proident, sunt in culpa qui officia deserunt mollit anim id est laborum.     
    	</p>
	    <p>
	      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
	      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
	      quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
	      consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
	      cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
	      proident, sunt in culpa qui officia deserunt mollit anim id est laborum..
		</p>
	</div>
</template>
<style scoped>
	p{
		font-size: 16px;
		padding-left: 5%;
		padding-right: 5%;
		font-style: italic;
	}
	h1{
		
		text-align: center;
		display: inline-block;
		color: white;
		animation: fadeIn 5s;

	}
	h1 { 
    
 /* Add animation properties */
 animation: fadeIn 2s ease-in; 
}
</style>