<template>
  <div class="main">
    <div class="about-section">
    <h1>Notre Equipe</h1>
  </div>
    <ul class="cards container"> 
    <li class="cards_item" v-for = "equipe in equipes" :key='equipe.id'>
      <div class="card">
        <!-- <div class="card_image" style="margin-left: 30%;"><img :src="'https://mkw-rdc.hogi.edu.bi' + equipe.get_thumbnail" style="margin-left: 30%;" style="width:100px"> </div> -->
        <div class="card_image">
          <img style="width:120px;margin-left: 25%;":src="equipe.logo"/>
        </div>
        <div class="card_content" style="text-align: center;">
          <h2 class="card_title">{{equipe.prenom}} {{equipe.nom}}</h2>
          <p>{{equipe.titre}}</p>
          <div class="card_text">
            <!-- <p> <i style="font-size:24px" class="fa">&#xf0e0;</i> {{equipe.email}}</p><hr> -->
            <!-- <p> <i style="font-size:24px" class="fa">&#xf095;</i> {{equipe.telephone}}</p><hr> -->
          </div>
        </div>
      </div>
    </li>
  </ul>    
  
</div>
</template>
<script>
  import axios from "axios"
  export default{
    data(){
      return {
        equipes:this.$store.state.equipes,
      }
    },
    mounted() {
        axios.get(this.$store.state.url + "/equipe/")
            .then(res => {
              this.equipes = res.data
              console.log(res.data)
            })
            .catch(err => {
                console.error(err); 
            })
        },  
  }
</script>
<style scoped>
  *,
*::before,
*::after {
  box-sizing: border-box;
}
span{
  text-align: justify;
  font-size: 14px;
  color: red;
}
/*.main {
  max-width: 1200px;
  margin: 0 auto;
}*/

.cards {
  display: grid;
  grid-template-columns:repeat(auto-fit,minmax(300px,1fr));
  list-style: none;
  margin: 5px;
  padding: 5px;
}
.about-section {
  text-align: justify;
  background-color: blue;
  text-align: center;
  color: white;
}
img {
/*  border-radius: 27%;*/
  border-top-left-radius: 50% 50%;
  border-top-right-radius: 50% 50%; 
  border-bottom-right-radius: 50% 50%; 
  border-bottom-left-radius: 50% 50%; 
}

.cards_item {
  display: inline-grid;
  margin: 10px;
  padding: 5px;
}

.card_image:before,
.card_image:after{
  content: "";
  position: absolute;
  width: 20px;
  left: 60%;
  top: 0;
  height: 45px;
  transform: rotate(45deg);
}
.card_image:after{
  transform: rotate(-45deg);
  top:auto;
  bottom:-22px;
}
.card_image img {
  width:100%;
  height:100%;
  object-fit:cover;
}

.cards_item {
  filter:drop-shadow(0 0 5px rgba(0, 0, 0, 0.25));
}


.card {
  border-radius: 0.25rem;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left:  2px;
}

.card_content {
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
}

h2.card_title,p {
  margin: 1.2rem 0;
}
h2.card_title {
    font-size: 1.3em;
}
.cards{
  border-width: 2px 4px;
  border-radius: 40px;
}

</style>