import { createStore } from 'vuex'

export default createStore({
  state: {
    user:null,
    url:"/api",
    // url:"http://127.0.0.1:8000/api",
    // url:"https://muryangowacu.nkokoz.com/api",
    // url: 'http://localhost:8000/api',
    logo:[],
    objectis:[],
    equipes:[],
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})