<template>
  <div id="app">
    <hotbar/>
    <navbar></navbar>
    <router-view/>
    <Footer></Footer>
  </div>
</template>
<script>
  import Hotbar from "@/components/Hotbar"
  import Navbar from "@/components/Navbar2"
  import Footer from "@/components/Footer"
  export default{
    components:{Footer, Navbar, Hotbar},
  }
</script>
<style >
  #app {
    width: 100%;
      background:
    url(https://source.unsplash.com/E8Ufcyxz514/2400x1823)
    center / cover no-repeat fixed;
    height: 100%;
  }
  h1{
    font-size: 24px;
    padding: 5px;
  }

</style>