<template>
<div class="rows">
  <div class="column">
    <div class="card">
      <div class="container">
        <h1>Pourquoi faire un don à <span style="color:red;">Muryango Wacu?</span></h1><br>
        <p>Si vous donnez maintenant! Sachez bien que vous apportez une assistance pour le changement de plusieurs vies. Merci de cliquer sur le bouton à côté pour découvrir comment procéder pour nous faire parvenir vos donations.</p>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped>


.about-section {
  padding: 50px;
  text-align: center;
  background-color: #474e5d;
  color: white;
}

.card {
  padding: 0 16px;
}

.card::after, .rows::after {
  content: "";
  clear: both;
  display: table;
}

.title {
  color: grey;
}

.button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.button:hover {
  background-color: #555;
}

@media screen and (max-width: 650px) {
  .column {
    width: 100%;
    display: block;
  }
}
@media (max-width: 540px)
.card {
    text-align: center;
    padding: 56px 15px;
}
@media (max-width: 767px)
.card {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.card{
    border-top: 3px solid #d61523;
    display: -ms-flexbox;
    display: flex;
    padding: 20px 20px;
    -ms-flex-align: flex-start;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 4.75px 0.25px rgba(0, 0, 0, 0.15);
    position: relative;

}
</style>