<template>
  <div class="main">
    
    <h1 style="text-align: center;"><span >Articles récents</span> <br> Nos objectifs</h1>  
    <ul class="cards">
    <li class="cards_item">
      <div class="card">
        <div class="card_image"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2v6hAkNT46QyZBSyrIsF_GpRClaRSwHu2WYbx0HjUjA&s" alt="mixed vegetable salad in a mason jar. "></div>
        <div class="card_content">
          <h2 class="card_title">Notre premier objectif </h2>
          <div class="card_text">
            <p> est de former les jeunes en matière d’ ...
            </p>
            <p><button class="card-btn">READ MORE</button></p>
          </div>
        </div>
      </div>
    </li>

    <li class="cards_item">
      <div class="card">
        <div class="card_image"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTi4cv29ZbepaDpouqcQBaUODA01HJ2ty9JccBjNy3Xg&s"></div>
        <div class="card_content">
          <h2 class="card_title">Notre second objectif </h2>
          <div class="card_text">
                        <h3></h3>
            <p>
             est de pratiquer l’élevage moderne des porcs, des  chèvres, ...
            </p>
            <p><button class="card-btn">READ MORE</button></p>
          </div>
        </div>
      </div>
    </li>
    <li class="cards_item">
      <div class="card">
        <div class="card_image"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSA_EJ1Mcl8q1c-4SSoE3H2qXTQmsmLgDOxq0O-ZpeckQ&s" alt="A side view of a plate of figs and berries. "></div>
        <div class="card_content">
          <h2 class="card_title">Notre  troisième objectif </h2>
          <div class="card_text">
            <p> est de pratiquer l’agriculture industrialisé des maïs,  ...
            </p>
            <p><button class="card-btn">READ MORE</button></p>
          </div>
        </div>
      </div>
    </li>
  </ul>    
  <ul class="cards">
    <li class="cards_item">
      <div class="card">
        <div class="card_image"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDeV3tkee3szYp6fow86aJ818aB0_8DDiovtYdZmzkNA&s" alt="mixed vegetable salad in a mason jar. "></div>
        <div class="card_content">
          <h2 class="card_title">Notre quatrième objectif </h2>
          <div class="card_text">
            <p>est de diminuer la hausse des prix de ces produits sur le marché. Cependant nous prévoyons ouvrir des dépôts de vente en gros ...
            </p>
            <p><button class="card-btn">READ MORE</button></p>
          </div>
        </div>
      </div>
    </li>

    <li class="cards_item">
      <div class="card">
        <div class="card_image"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTo5q6HhC09WQWAbq_RT4JmKA-Z-nPNeJdSH_9Dg-2Xkw&s" alt="a Reuben sandwich on wax paper. "></div>
        <div class="card_content">
          <h2 class="card_title">Notre cinquième objectif </h2>
          <div class="card_text">
            <p>est d’aider les jeunes qui n’ont pas eu la chance d’obtenir un diplôme ou ceux qui n’ont pas eu la chance ...
            </p>
            <p><button class="card-btn">READ MORE</button></p>

          </div>
        </div>
      </div>
    </li>
  </ul>
  <bref/>
</div>
</template>
<script>
	import bref from './Bref.vue'
	export default {
		components:{
			bref
		}
	}
</script>

<style scoped>
  *,
*::before,
*::after {
  box-sizing: border-box;
}
span{
  text-align: center;
  font-size: 14px;
  color: red;
}
.main {
  max-width: 1200px;
  margin: 0 auto;
}

.cards {
  display: grid;
  grid-template-columns:repeat(auto-fit,minmax(300px,1fr));
  list-style: none;
  margin: 0;
  padding: 0;
}

.cards_item {
  display: flex;

  padding: 1rem;
}

.card_image {
  height: calc(13*1.2rem);
  padding: 1.2rem 1.2rem 0;
  position:relative;
}
.card_image:before,
.card_image:after{
  content: "";
  position: absolute;
  width: 20px;
  left: 60%;
  top: 0;
  height: 45px;
  background: #e6e6e6b8;
  transform: rotate(45deg);
}
.card_image:after{
  transform: rotate(-45deg);
  top:auto;
  bottom:-22px;
  left:40%;
}
.card_image img {
  width:100%;
  height:100%;
  object-fit:cover;
}

.cards_item {
  filter:drop-shadow(0 0 5px rgba(0, 0, 0, 0.25));
}


.card {
  background-color: white;
  border-radius: 0.25rem;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
}

.card_content {
  padding: 1.2rem;
}

h2.card_title,p {
  margin: 1.2rem 0;
}
h2.card_title {
    font-size: 1.3em;
}
body {
  font-family:monospace;
  background:#eee;
}

html {
  font-size:15px;
}
 .card-btn{
     all: unset;
     display: block;
     margin-left: auto;
     border: 2px solid #2934b9;
     padding: 10px 15px;
     border-radius: 25px;
     font-size: 10px;
     font-weight: 600;
     transition: all 0.5s;
     cursor: pointer;
     letter-spacing: 1.2px;
}
 .card-btn:hover{
    color:#02b875;
    background: #FFF;
}
 .card:nth-child(2) .card-btn:hover{
    color:#4181ee;
    background: #FFF;
}
 .card:last-child .card-btn:hover{
    color:#673ab7;
     background: #FFF;
}

</style>