import { createRouter, createWebHistory } from 'vue-router'
import Home from '../Pages/Home.vue'
import Equipe from '../Pages/Equipe.vue'
import Objectifs from '../Pages/Objectifs.vue'
import Don from '../Pages/Don.vue'
import Temoignage from '../Pages/Temoignage.vue'
import Contact from '../Pages/Contact.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/equipe',
    name: 'equipe',
    component: () => import( '../Pages/Equipe.vue')
  },
  {
    path: '/don',
    name: 'don',
    component: () => import( '../Pages/Don.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import( '../Pages/Contact.vue')
  },
  {
    path: '/temoignage',
    name: 'temoignage',
    component: () => import( '../Pages/Temoignage.vue')
  },
  {
    path: '/objectifs',
    name: 'objectifs',
    component: () => import( '../Pages/Objectifs.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiredLogin)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.isAuthenticated) {
      next({ name: 'login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router
