<template>
  <div>
    <Presentation/>
    <Card/>
    <Objectif/>
    <Aide/>
  </div>
</template>

<script>
import Presentation from '@/components/Presentation.vue'
import Objectif from '@/components/Objectif.vue'
import Offers from '@/components/Offers.vue'
import Aide from '@/components/Aide.vue'
import Card from '@/components/Card.vue'

export default {
  name: 'App',
  components: {
    Presentation,
    Card,
    Objectif,
    Offers,
    Aide,
  }
}
</script>