<template>
<div class="rows">
    <div class="about-section">
      <h1>Faire un don</h1>
    </div>
  <div class="column">
    <div class="card">
      <div class="container">
        <h1>Chaque don permet d’apporter une assistance aux enfants, jeunes, femmes et familles en difficulté.</h1><br>
        <p>Vous pouvez sauver des vies en situations difficile et sachez bien que quand vous faites un don à <strong>Muryango Wacu - MSG</strong>, vous contribuez directement à la construction du monde.</p>
        <p>Nous ne pouvons pas agir sans votre soutien. <strong>Muryango Wacu - MSG</strong> étant entièrement financé par des dons volontaires, chacune de vos contributions compte.</p>
        <p>Agissez dès aujourd’hui. Aidez-nous à construire un monde meilleur en faisant un don.</p>
        <p>Nous vous tiendrons informé du travail réalisé par le <strong>Muryango Wacu - MSG</strong> et des vies sauvées grâce à votre générosité.</p><br>
        <h5>Pour faire un don à <strong>Muryango Wacu - MSG</strong> par virement bancaire depuis n’importe où au monde, veuillez utiliser les informations suivantes :</h5>
        <ul style="list-style-type:disc;">
          <!-- <li><strong>Code suift</strong>           : TRMSCD3L</li> -->
          <li><strong>Intitulé du compte</strong>   : SOC Umuryango Wacu</li>
          <li><strong>N° du Compte</strong>         : 212486 </li>
          <li><strong>Nom de la Banque</strong>     : C.E.C.M</li>
          <li><strong>Devise</strong>               : USD ou FBU</li>
          <li><strong>Mention</strong>              : Aide aux ....</li>
          <!-- <li><strong>Adresse de la banque</strong> : 1, Av du Congo – Kimanga, Uvira, Sud-Kivu, RDC.</li> -->
        </ul>
      </div>
    </div>
  </div>
</div>
</template>
<style scoped>
.cards {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.about-section {
  text-align: center;
  background-color: blue;
  color: white;
}

.card {
  padding: 0 16px;
}

.card::after, .rows::after {
  content: "";
  clear: both;
  display: table;
}

.title {
  color: grey;
}

.button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.button:hover {
  background-color: #555;
}

@media screen and (max-width: 650px) {
  .column {
    width: 100%;
    display: block;
  }
}
@media (max-width: 540px)
.card {
    text-align: center;
    padding: 56px 15px;
}
@media (max-width: 767px)
.card {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.card{
    display: -ms-flexbox;
    display: flex;
    padding: 20px 20px;
    -ms-flex-align: flex-start;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 4.75px 0.25px rgba(0, 0, 0, 0.15);
    position: relative;

}
</style>