<template>
  <!-- Navbar -->
<nav class="navbar navbar-expand-lg navbar-dark bg-primary">
    <!-- Container wrapper -->
    <div class="container">
        <!-- <a class="navbar-brand text mb-1">Navbar</a> -->
      <center>
      <div class="collapse navbar-collapse" id="navbarButtonsExample">
            <!-- Left links -->
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                    <!-- <a class="nav-link" href="#">Home</a> -->
                </li>
            </ul>
            <!-- Left links -->

            <ul class="navbar-nav d-flex flex-row"> 
                <!-- Icons -->
                <li class="nav-item me-3 me-lg-0">
                    <a class="nav-link" href="#">
                        <i style="margin: 10px; color: white; font-size:32px" class="fa fa-envelope text-white"></i>
                    </a>
                </li>
                <li class="nav-item me-3 me-lg-0">
                    <a class="nav-link" href="#">
                        <i style="margin: 10px;color: white; font-size:32px" class="fa fa-facebook-f text-white"></i>
                    </a>
                </li>
                <li class="nav-item me-3 me-lg-0">
                    <a class="nav-link" href="#">
                        <i style="margin: 10px;color: white; font-size:32px" class="fa fa-instagram text-white"></i>
                    </a>
                </li>
                <li class="nav-item me-3 me-lg-0">
                    <a class="nav-link" href="#">
                        <i style="margin: 10px;color: white; font-size:32px" class="fa fa-linkedin text-white"></i>
                    </a>
                </li>
                <li class="nav-item me-3 me-lg-0">
                    <a class="nav-link" href="#">
                        <i style="margin: 10px;color: white; font-size:32px" class="fa fa-github text-white"></i>
                    </a>
                </li>
                <li class="nav-item me-3 me-lg-0">
                    <a class="nav-link" href="#">
                        <i style="margin: 10px;color: white; font-size:32px" class="fa fa-twitter text-white"></i>
                    </a>
                </li>
            </ul>
            
        </div>
      </center>
        <!-- Collapsible wrapper -->
    </div>
    <!-- Container wrapper -->
</nav>
<!-- Navbar -->
</template>
<style scoped>
  .bg-primary {
    background-color: #2243eeb5 !important;
}
</style>